/**
 * @param {*} documentResult 
 * @description Provides pagination links to next and last pages
 * @returns 
 */
export function getCursorLinks(documentResult) {
    let next
    let previous

    if (documentResult.result.nextPageToken) {
        next = new URL(window.location.href)
        next.searchParams.set('pageToken', documentResult.result.nextPageToken)
    }


    if (documentResult.result.previousPageToken) {
        previous = new URL(window.location.href)
        previous.searchParams.set('pageToken', documentResult.result.previousPageToken)
    }


    return {
        next: next && next.toString(),
        previous: previous && previous.toString()
    }
}


/**
 * @description Adds the links to the cursor 
 */
export function addPaginationLinks(documentResult) {
    const div = document.createElement('div')
    const cursorLinks = getCursorLinks(documentResult)


    div.className = 'row'

    div.innerHTML = `
        <div class="col">
            <ul class="pagination">
                <li class="page-item"><a class="page-link previous" href="${cursorLinks.previous}">Previous</a></li>
                <li class="page-item"><a class="page-link next" href="${cursorLinks.next}">Next</a></li>
            </ul>
        </div>
    `

    return div
}