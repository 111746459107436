const firestoreWorker = 'https://worker-firestore-dev.programmerhannan.workers.dev'


async function fetchFirestore (path, queryOptions) {
    const url = `${firestoreWorker}${path}?${new URLSearchParams(queryOptions).toString()}`

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })


    return response.json()
}


export function fetchBlogs (queryOptions) {
    return fetchFirestore('/blogs', queryOptions)
}


export function fetchComments (blogId, queryOptions) {
    return fetchFirestore(`/blog/${blogId}/comments`, queryOptions)
}

export function fetchBlog (blogId, queryOptions) {
    return fetchFirestore(`/blog/${blogId}`, queryOptions)
}

